
import React from 'react';

export default class Footer extends React.Component {

    render() {
        return (
            <footer className="has-text-right">
                <a name="footer"/>
                <div className="container">
                    {this.props.children}
                </div>
            </footer>
        )
    }
}