export const zapierWebhook =
  "https://hooks.zapier.com/hooks/catch/4208615/jvyzq8/";

export default zapierWebhook;

export const advocaten = [
  {
    naam: "MAARTEN MEIJER",
    tags: [
      "AANSPRAKELIJKHEIDSRECHT",
      "ARBEIDS- EN SOCIAAL ZEKERHEIDSRECHT",
      "BESTUURSRECHT",
      "ERFRECHT",
      "FINANCIEEL RECHT",
      "GOEDERENRECHT",
      "HUURRECHT",
      "PERSONEN- EN FAMILIERECHT",
      "STRAFRECHT",
      "VERBINTENISSENRECHT"
    ],
    ervaring: "30",
    tarief: 125,
    email: "bartmeijer@mesadvocaten.nl",
    link: "http://mstrs.nl/advocaat/meijer-en-sarin-advocaten",
    image: "maarten_meijer.png"
  },
  {
    naam: "RENÉ MENTING",
    tags: [
      "ARBEIDS- EN SOCIAAL ZEKERHEIDSRECHT",
      "BESTUURSRECHT",
      "HUURRECHT",
      "STRAFRECHT",
      "VERBINTENISSENRECHT"
    ],
    ervaring: "5",
    tarief: 150,
    email: "r.menting@hetwetshuys.nl",
    link: "http://mstrs.nl/advocaat/rene-menting",
    image: "rene_menting.jpg"
  },
  {
    naam: "ANIEK HOLLMAN",
    tags: [
      "JEUGDRECHT",
      "PERSONEN- EN FAMILIERECHT",
      "STRAFRECHT",
      "VERBINTENISSENRECHT"
    ],
    ervaring: "9",
    tarief: 150,
    email: "a.hollman@hetwetshuys.nl",
    link: "http://mstrs.nl/advocaat/aniek-hollman",
    image: "aniek_hollman.jpg"
  },
  {
    naam: "PETRA VAN ENCKEVORT",
    tags: [
      "ARBEIDS- EN SOCIAAL ZEKERHEIDSRECHT",
      "BESTUURSRECHT",
      "ERFRECHT",
      "HUURRECHT",
      "JEUGDRECHT",
      "PERSONEN- EN FAMILIERECHT",
      "STRAFRECHT",
      "VERBINTENISSENRECHT"
    ],
    ervaring: "15",
    tarief: 150,
    email: "p.vanenckevort@hetwetshuys.nl",
    link: "http://mstrs.nl/advocaat/petra-van-enckevort",
    image: "petra_van_enckevort.jpg"
  },
  {
    naam: "QUIRINE OVEREIJNDER",
    tags: [
      "AANSPRAKELIJKHEIDSRECHT",
      "ARBEIDS- EN SOCIAAL ZEKERHEIDSRECHT",
      "BESTUURSRECHT",
      "ERFRECHT",
      "GOEDERENRECHT",
      "HUURRECHT",
      "INSOLVENTIERECHT",
      "JEUGDRECHT",
      "ONDERNEMINGSRECHT",
      "PERSONEN- EN FAMILIERECHT",
      "STRAFRECHT",
      "VERBINTENISSENRECHT"
    ],
    ervaring: "12",
    tarief: 150,
    email: "overeijnder@replieck.nl",
    link: "http://mstrs.nl/advocaat/quirine",
    image: "quirine_overeijnder.jpg"
  },
  {
    naam: "SUDHA VERMEULEN",
    tags: [
      "ARBEIDS- EN SOCIAAL ZEKERHEIDSRECHT",
      "JEUGDRECHT",
      "PERSONEN- EN FAMILIERECHT",
      "STRAFRECHT",
      "VERBINTENISSENRECHT"
    ],
    ervaring: "11",
    tarief: 165,
    email: "info@vermeulenadvocatuur.nl",
    link: "http://mstrs.nl/advocaat/sudha-vermeulen",
    image: "sudha_vermeulen.jpg"
  },
  {
    naam: "INGRID VAN DEN HEUVEL-BEERENS",
    tags: ["ERFRECHT", "JEUGDRECHT", "STRAFRECHT"],
    ervaring: "9",
    tarief: 165,
    email: "ingrid@dezaakinrecht.nl",
    link: "http://mstrs.nl/advocaat/dezaakinrecht",
    image: "ingrid_van_den_heuvel_beerens.jpg"
  },
  {
    naam: "BRENDA VAN HAEFTEN",
    tags: ["ERFRECHT", "JEUGDRECHT", "STRAFRECHT"],
    ervaring: "7",
    tarief: 175,
    email: "b.vanhaeften@vhbadvocaten.nl",
    link: "http://mstrs.nl/advocaat/brendavanhaeften",
    image: "brenda_van_haeften.png"
  },
  {
    naam: "INDRA VAN STEENES",
    tags: [
      "AANSPRAKELIJKHEIDSRECHT",
      "BESTUURSRECHT",
      "HUURRECHT",
      "INSOLVENTIERECHT",
      "STRAFRECHT",
      "VERBINTENISSENRECHT"
    ],
    ervaring: "2",
    tarief: 180,
    email: "vansteenes@advocatenkantoor.nl",
    link: "http://mstrs.nl/advocaat/vansteenes",
    image: "indra_van_steenes.jpg"
  },
  {
    naam: "ANNIEKE BLOEMBERG",
    tags: ["JEUGDRECHT", "STRAFRECHT"],
    ervaring: "11",
    tarief: 180,
    email: "bloemberg@bloembergadvocatuur.nl",
    link: "http://mstrs.nl/advocaat/anniekebloemberg",
    image: "annieke_bloemberg.jpg"
  },
  {
    naam: "NANDA BALDEW",
    tags: ["ERFRECHT", "JEUGDRECHT", "STRAFRECHT"],
    ervaring: "17",
    tarief: 185,
    email: "r.n.baldew@advocatenkantoorbaldew.nl",
    link: "http://mstrs.nl/advocaat/nandabaldew",
    image: "nanda_baldew.jpg"
  },
  {
    naam: "LEONIE VAN DER GRINTEN",
    tags: ["STRAFRECHT"],
    ervaring: "4",
    tarief: 190,
    email: "vandergrinten@meesterleonie.nl",
    link: "http://mstrs.nl/advocaat/leonievandergrinten",
    image: "leonie_van_der_grinten.jpg"
  },
  {
    naam: "KHADIJA BOZIA",
    tags: ["BESTUURSRECHT", "FISCAAL RECHT", "STRAFRECHT"],
    ervaring: "13",
    tarief: 200,
    email: "bozia@bnbadvocatuur.nl",
    link: "http://mstrs.nl/advocaat/bnb-advocatuur",
    image: "khadija_bozia.jpg"
  }
];
