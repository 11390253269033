import React from "react";

export class CookieConsent extends React.Component {
  static async getInitialProps({ req }) {
    const userAgent = req ? req.headers["user-agent"] : navigator.userAgent;
    return { userAgent };
  }

  componentDidMount() {
    // eslint-disable-next-line global-require
    require("cookieconsent");
    window.cookieconsent.initialise({
      container: document.getElementById("cookiebar"),
      palette: {
        popup: { background: "#f6cf34" },
        button: {
          background: "transparent",
          text: "#000",
          border: "#000"
        }
      },
      revokable: false,
      law: {
        regionalLaw: false
      },
      location: true
    });
  }

  render() {
    return <div id="cookiebar" />;
  }
}

export default CookieConsent;
