import React from "react";
import FontAwesomeIcon from "react-fontawesome";

import { emitter } from "@marvelapp/react-ab-test";

import axios from "axios";
import { emailRegex, phoneRegexFixed, phoneRegexMobile } from "../regex";
import TrackingButton from "./TrackingButton";

export default class Signup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      casus: "",
      email: "",
      phone: "",
      isNameValid: true,
      isCasusValid: true,
      isEmailValid: true,
      isPhoneValid: true,
      isSignupCompleted: false,
      signUpErrorMessageName: null,
      signUpErrorMessageCasus: null,
      signUpErrorMessageEmail: null,
      signUpErrorMessagePhone: null
    };
  }

  // change also resets the error message
  //
  handleChangeName = e => {
    this.setState({
      name: e.target.value,
      signUpErrorMessageName: "",
      isNameValid: true
    });
  };

  // change also resets the error message
  //
  handleChangeCasus = e => {
    this.setState({
      casus: e.target.value,
      signUpErrorMessageCasus: "",
      isCasusValid: true
    });
  };

  // change also resets the error message
  //
  handleChangeEmail = e => {
    this.setState({
      email: e.target.value,
      signUpErrorMessageEmail: "",
      isEmailValid: true
    });
  };

  // change also resets the error message
  //
  handleChangePhone = e => {
    this.setState({
      phone: e.target.value,
      signUpErrorMessagePhone: "",
      isPhoneValid: true
    });
  };

  onSubmit = async e => {
    e.preventDefault();

    const { name, email, casus, phone } = this.state;

    const minNameLength = 1;
    const minCasusLength = 1;

    // check validity
    const isNameValid = name.length >= minNameLength;
    const isCasusValid = casus.length >= minCasusLength;
    const isEmailValid = emailRegex.test(email);
    const isPhoneValid =
      phoneRegexFixed.test(phone) || phoneRegexMobile.test(phone);

    var ok = true;

    if (!isNameValid) {
      this.setState({
        isNameValid: isNameValid,
        signUpErrorMessageName: "Voer uw name in"
      });
      ok = false;
    }

    if (!isCasusValid) {
      this.setState({
        isCasusValid: isCasusValid,
        signUpErrorMessageCasus:
          "We hebben wat meer informatie nodig om u te kunnen helpen"
      });
      ok = false;
    }

    if (!isEmailValid) {
      this.setState({
        isEmailValid: isEmailValid,
        signUpErrorMessageEmail: "Voer een geldig email adres in"
      });
      ok = false;
    }

    if (!isPhoneValid) {
      this.setState({
        isPhoneValid: isPhoneValid,
        signUpErrorMessagePhone: "Voer een geldig telefoonnummer in"
      });
      ok = false;
    }

    if (!ok) {
      this.setState({ submitting: false });
      return;
    }

    this.setState({ submitting: true });

    await this.sendSignup();

    // Send event to Segment
    //
    emitter.emitWin(this.props.experiment);

    // reset
    //
    this.setState({
      email: "",
      name: "",
      casus: "",
      phone: "",
      signUpErrorMessageName: "",
      signUpErrorMessageCasus: "",
      signUpErrorMessageEmail: "",
      signUpErrorMessagePhone: ""
    });
  };

  sendSignup = () => {
    const { email, phone, name, casus } = this.state;
    axios.get(
      `${
        this.props.endpoint
      }?email=${email}&phone=${phone}&name=${name}&casus=${casus}`
    );
    this.setState({
      isSignupCompleted: true,
      submitting: false
    });

    // make the message go away again after 3 sec
    //
    setTimeout(() => {
      this.setState({ isSignupCompleted: false });
    }, 3000);
  };

  render() {
    const {
      isSignupCompleted,
      submitting,
      signUpErrorMessageName,
      signUpErrorMessageCasus,
      signUpErrorMessageEmail,
      signUpErrorMessagePhone,
      email,
      name,
      casus,
      phone,
      isNameValid,
      isCasusValid,
      isEmailValid,
      isPhoneValid
    } = this.state;

    // isSignupCompleted = true;
    // submitting = true;
    // isEmailValid = false;
    // signUpErrorMessage = "Test Error!"

    let style = "";
    if (this.props.image) {
      style = {
        backgroundImage: `url(${this.props.image})`,
        backgroundSize: "cover"
      };
    }

    return (
      <section ref="signup" className="signup is-medium" style={style}>
        <a name="signup" />
        {!isSignupCompleted && (
          <div className="container">
            <h2 className="section-title">{this.props.cta}</h2>
            <p>{this.props.explain}</p>
            <div className="signup-form">
              <form
                id="signup__form"
                className="signup__form"
                onSubmit={this.onSubmit}>
                <div className="field signup-field-email">
                  <div className="control">
                    <input
                      className={`input ${!isNameValid ? "is-danger" : ""}`}
                      type="text"
                      placeholder="Naam"
                      value={name}
                      onChange={this.handleChangeName}
                    />
                  </div>
                  {signUpErrorMessageName && (
                    <span className="signup-error is-danger">
                      {signUpErrorMessageName}
                    </span>
                  )}
                </div>
                <div className="field signup-field-email">
                  <div className="control">
                    <input
                      className={`input ${!isCasusValid ? "is-danger" : ""}`}
                      type="text"
                      placeholder="Situatie"
                      value={casus}
                      onChange={this.handleChangeCasus}
                    />
                  </div>
                  {signUpErrorMessageCasus && (
                    <span className="signup-error is-danger">
                      {signUpErrorMessageCasus}
                    </span>
                  )}
                </div>
                <div className="field signup-field-email">
                  <div className="control">
                    <input
                      className={`input ${!isEmailValid ? "is-danger" : ""}`}
                      type="text"
                      placeholder="E-mail adres"
                      value={email}
                      onChange={this.handleChangeEmail}
                    />
                    <FontAwesomeIcon
                      className="fa email-icon"
                      name="envelope"
                    />
                  </div>
                  {signUpErrorMessageEmail && (
                    <span className="signup-error is-danger">
                      {signUpErrorMessageEmail}
                    </span>
                  )}
                </div>
                <div className="field signup-field-email">
                  <div className="control">
                    <input
                      className={`input ${!isPhoneValid ? "is-danger" : ""}`}
                      type="text"
                      placeholder="Telefoonnummer"
                      value={phone}
                      onChange={this.handleChangePhone}
                    />
                    <FontAwesomeIcon className="fa email-icon" name="phone" />
                  </div>
                  {signUpErrorMessagePhone && (
                    <span className="signup-error is-danger">
                      {signUpErrorMessagePhone}
                    </span>
                  )}
                </div>
                <div className="field signup-field-button">
                  <div className="control">
                    <TrackingButton
                      id="signup_cta"
                      experiment={this.props.experiment}
                      extraClasses={`is-large ${
                        submitting ? "is-loading" : ""
                      }`}>
                      Verzenden
                    </TrackingButton>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
        {isSignupCompleted && (
          <div className="container">
            <h2 className="signup-success is-success">
              {this.props.successMsg}
            </h2>
          </div>
        )}
      </section>
    );
  }
}
