import React from "react";

export default class StaticHeader extends React.Component {
  render() {
    let review;

    if (this.props.showRating) {
      review = (
        <div className="review-rating is-pulled-right">
          <iframe
            allowtransparency="true"
            src="https://klantenvertellen.nl/widget/advocaatzoeken/meester_leonie/"
            width="143"
            height="192"
            scrolling="no"
          />
          ;
        </div>
      );
    } else {
      review = "";
    }
    return (
      <section id={this.props.cssId} className="hero is-medium header">
        <a name="header" />
        <div className="static-header leonie">
          <div className="container">
            <div className="columns is-desktop">
              <img src={this.props.image} className="is-pulled-left column static-header-img" />

              <div className="header-item-box column">
                <p className="header-item-title">{this.props.title}</p>
                <p className="header-item-tagline">
                  <span className="highlight">{this.props.tagline}</span>
                </p>
                <div className="header-item-button-wrapper">
                  <a className="header-item-button button" href={this.props.href}>
                    {this.props.buttonText}
                  </a>
                </div>

                {review}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
