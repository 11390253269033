import React from "react";

// Page wide banner
//
export default class Banner extends React.Component {
  render() {
    return (
      <section className="banner" id={this.props.id}>
        <div className="level">
          <div className="level-item columns">{this.props.children}</div>
        </div>
      </section>
    );
  }
}
