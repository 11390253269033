import React from "react";
import Carousel from "nuka-carousel";
import "./App.scss";

import { zapierWebhook, advocaten } from "./config";
import Banner from "./components/Banner";
import CookieConsent from "./components/CookieConsent";
import Feature from "./components/Feature/Feature";
import Features from "./components/Feature/Features";
import Footer from "./components/Footer/Footer";
import FooterItem from "./components/Footer/FooterItem";
import InDepth from "./components/InDepth/InDepth";
import Lawyer from "./components/Lawyer";
import Signup from "./components/Signup";
import StaticHeader from "./components/Header/StaticHeader";
import TrackingButton from "./components/TrackingButton";

import {
  Experiment,
  Variant,
  emitter,
  experimentDebugger,
  segmentHelper
} from "@marvelapp/react-ab-test";

// define variants and global _experiment name for buttons to use
// @TODO make this come from some kind of config
//
const _experiment = "Experiment 1";
emitter.defineVariants(_experiment, ["A", "B"], [50, 50]);

// Called when the experiment is displayed to the user.
//
emitter.addPlayListener(function(experimentName, variantName) {
  console.log(`Displaying experiment ${experimentName} variant ${variantName}`);
});

// Called when a 'win' is emitted, in this case by this.refs.experiment.win()
//
emitter.addWinListener(function(experimentName, variantName) {
  console.log(
    `Variant ${variantName} of experiment ${experimentName} was clicked`
  );
});

segmentHelper.enable();
experimentDebugger.enable();

function App() {
  return (
    <div className="App">
      <Experiment name={_experiment}>
        <Variant name="A">
          <Banner id="topbanner">
            <div className="column logo is-pulled-left">
              <img src="/assets/logo-mstrs.svg" alt="Mstrs - Online Advocaten" />
            </div>
            <div className="column is-pulled-right">
              <TrackingButton
                id="topbanner"
                experiment={_experiment}
                extraClasses="full-width is-large"
                href="#signup">
                Krijg je rijbewijs terug
                <span className="tmi_icon">:direction:</span>
              </TrackingButton>
            </div>
          </Banner>
          <StaticHeader
            cssId="leonie"
            image="/assets/meester-leonie.png"
            title="Ik help jou je rijbewijs terug te krijgen."
            tagline="Duidelijk, transparant en persoonlijk"
            extraText="01"
            buttonText="Neem contact met mij op"
            trackId="header-personal"
            href="#signup"
            showRating="true"
            extraClasses="personal"
          />
          <Features title="In drie stappen je rijbewijs terug">
            <Feature
              nr="1"
              title="Gratis en vrijblijvend intake gesprek"
              text="In het gratis intake gesprek nemen we je zaak door en leg ik de procedure duidelijk uit."
              buttonText="Neem contact met mij op"
              href="#signup"
              trackId="feature-snel-resultaat"
              experiment={_experiment}
            />
            <Feature
              nr="2"
              title="Direct inschatting van je kansen en de kosten"
              text="Na het gesprek weet je meteen waar je aan toe bent en wat je kansen zijn om je rijbewijs snel terug te krijgen."
              buttonText="Neem contact met mij op"
              href="#signup"
              trackId="feature-snel-resultaat"
              experiment={_experiment}
            />
            <Feature
              nr="3"
              title="Zo snel mogelijk een goed resultaat"
              text="Maak je kans? Dan zorg ik dat we zo snel mogelijk voor de rechter staan."
              buttonText="Neem contact met mij op"
              href="#signup"
              trackId="feature-snel-resultaat"
              experiment={_experiment}
            />
          </Features>
          <Signup
            image="/assets/bgSignup.jpg"
            cta="Neem contact met mij op"
            explain="Vertel iets over je zaak en ik bel je zo snel mogelijk terug!"
            endpoint={zapierWebhook}
            tags="landing_page_prospect"
            experiment={_experiment}
            successMsg="Bedankt! Ik neem zo snel mogelijk contact op!"
            showRating="false"
          />
          <Carousel
            slideWidth="340px"
            withoutControls
            autoplay
            wrapAround
          >
            {advocaten.map((item, index) => {
              return (
                <Lawyer key={index} data={item} show={true} index={index} />
              );
            })}
          </Carousel>
        </Variant>

        <Variant name="B">
          <Banner id="topbanner">
            <div className="column logo is-pulled-left">
              <img src="/assets/logo-mstrs.svg" alt="Mstrs | Online Advocaten"/>
            </div>
            <div className="column is-pulled-right">
              <TrackingButton
                id="topbanner"
                experiment={_experiment}
                extraClasses="full-width is-large"
                href="#signup">
                Snel uw rijbewijs terug
                <span className="tmi_icon">:direction:</span>
              </TrackingButton>
            </div>
          </Banner>
          <StaticHeader
            cssId="variant-b-rijbewijs"
            image="/assets/rijbewijs.png"
            title="Snel je rijbewijs terug."
            tagline="Met hulp van een MSTRS advocaat snel je rijbewijs terug."
            buttonText="Contact opnemen"
            trackId="header-traditional"
            href="#signup"
            showRating={false}
            extraClasses="traditional"
          />
          <Features title="In drie stappen je rijbewijs terug">
            <Feature
              nr="1"
              title="Gratis en vrijblijvend intake gesprek"
              text="In het gratis intake gesprek neemt een advocaat uw zaak door en wordt de juridische procedure duidelijk uitgelegd."
              buttonText="Contact opnemen"
              href="#signup"
              trackId="feature-snel-resultaat"
              experiment={_experiment}
            />
            <Feature
              nr="2"
              title="Direct inzicht in de kosten"
              text="Na het gesprek weet u meteen waar u aan toe bent en wat uw kansen zijn om uw rijbewijs snel terug te krijgen."
              buttonText="Contact opnemen"
              href="#signup"
              trackId="feature-snel-resultaat"
              experiment={_experiment}
            />
            <Feature
              nr="3"
              title="Zo snel mogelijk een goed resultaat"
              text="Maak u kans? Dan zorgt de advocaat dat uw zaak zo snel mogelijk voor de rechter komt."
              buttonText="Contact opnemen"
              href="#signup"
              trackId="feature-snel-resultaat"
              experiment={_experiment}
            />
          </Features>
          <Signup
            image="/assets/bgSignup.jpg"
            cta="Contact opnemen"
            explain="Vul het formulier in en een advocaat belt je zo snel mogelijk terug!"
            endpoint={zapierWebhook}
            tags="landing_page_prospect"
            experiment={_experiment}
          />
          <InDepth title="Vestigingslocaties">
            <div className="columns">
              <div className="column map-column">
                <img className="map" src="/assets/netherlands_map.png" alt="Netherlands" />
              </div>
              <div className="column text-column">
                <h2>Overal een MSTRS advocaat in de buurt</h2>
                <p>
                  Al meer dan 40 topadvocaten in Nederland zijn aangesloten bij
                  MSTRS. Kwaliteit gegarandeerd!
                </p>
              </div>
            </div>
          </InDepth>
        </Variant>
      </Experiment>

      <CookieConsent />
      <Footer>
        <FooterItem href="https://mstrs.nl/contact/">Contact</FooterItem>
        <FooterItem href="http://mstrs.nl/wp-content/uploads/2018/10/privacyverklaring_mstrs.pdf">
          Privacy Policy
        </FooterItem>
        <FooterItem noAnchor={true}>
          <img src="/assets/logo-mstrs-white.svg" alt="MSTRS logo" />
        </FooterItem>
      </Footer>
    </div>
  );
}

export default App;
