import React from 'react';

export default class FooterItem extends React.Component {

    render() {
        let className = "";
        if (!this.props.noAnchor) {
            className = "anchor";
        }
        return (
            <p>
                <a className={className} href={this.props.href} target="_blank" rel="noopener noreferrer">
                    {this.props.children}
                </a>
            </p>
        )
    }
}