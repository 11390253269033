import React from "react";

// Lawyer
//
export default class Lawyer extends React.Component {
  render() {
    return (
      <div className="lawyer">
        <div className="lawyer-image-wrapper">
          <img
            className="lawyer-image"
            src={"/assets/" + this.props.data.image}
            alt="carousel"
          />
        </div>
        <div className="lawyer_stats">
          <h2>{this.props.data.naam}</h2>
          <span className="lawyer-tags">{this.props.data.tags.join(", ")}</span>
          <p>Werkervaring: {this.props.data.ervaring} jaar</p>
          <p>Uurtarief: €{this.props.data.tarief},-</p>
          <a
            className="button is-dark full-width"
            target="_blank"
            href={this.props.data.link}>
            Neem contact op
          </a>
        </div>
      </div>
    );
  }
}
