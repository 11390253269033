import React from "react";

export default class InDepth extends React.Component {
  // TODO ADD CAROUSEL

  render() {
    return (
      <section className="in-depth">
        <a name="in-depth" />
        <div className="container">
          <h2 className="section-title">{this.props.title}</h2>
          {this.props.children}
        </div>
      </section>
    );
  }
}
